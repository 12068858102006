export const PROJECTS_LIST = [
  {
    title: "DUBB CLUB",
    imageName: "DubbClub.png",
    text:
      "Dubb Club is a site made to show custom sports predictions for various leagues. The project " +
      "was developed as part of a senior project by myself and 4 others. Using recent and live " +
      "data, Dubb Club shows a user predicted winners of upcoming games, allows them to vote on who " +
      "they think the winner is and customize their dashboard through favoriting. I also worked on " +
      "developing a mobile app as a companion for the site. Dubb Club does a whole " +
      "lot more as well, so check out our site if you're interested!",
    date: "January 2021 - Present",
    link: "https://github.com/hughbromund/DubbClub",
    languages: ["Express.js", "MongoDB", "Python", "React", "Flask", "SwiftUI"],
  },
  {
    title: "JUSTIFI",
    imageName: "Justifi.jpeg",
    text:
      "Justifi was created with 2 friends for Boilermake VIII and took 2nd place. The project was a " +
      "mobile app that allowed users to create a chain of video questions and responses. The backend " +
      "functionality also allowed us to filter out videos with toxic and vulgar content. Additionally, " +
      "the app used the same process to categorize videos and display them to users having liked videos " +
      "in similar categories. This created a safe environment as well as a curated one.",
    date: "January 2021",
    link: "https://github.com/hughbromund/Justifi",
    languages: ["SwiftUI", "Python", "Express.js", "MongoDB", "Cloudflare", "React"],
  },
  {
    title: "MARKET GO BRR",
    imageName: "MarketGoBrr.png",
    text:
      "MarketGoBrr was a project developed for VandyHacks VII by myself and 3 friends and placed top 4 " +
      "in the competition. The site (when functional) looks at a twitter user's tweet history and analyzes " +
      "their sentiment. Using this, and an input stock price, it calculates a measure of how much a specific " +
      "user's tweets correlates to a change in stock price.",
    date: "October 2020",
    link: "https://github.com/hughbromund/MarketGoBrr",
    languages: ["React", "Express.js", "Python", "SQL"],
  },
  {
    title: "ADITYA-NAIK.COM",
    imageName: "AdityaNaikSite.png",
    text:
      "During my freshman year at Purdue, I decided it was time for me to build a website " +
        "partially to learn React but also in order to fill out any fields on " +
        "internship applications that were related. After my second year of college, " +
        "I decided it was time for a redesign.",
    date: "October 2018 / May 2020",
    link: "https://github.com/adityan9900/Aditya-Naik.com-2.0",
    languages: ["React"],
  },
  {
    title: "FINEX",
    imageName: "FinexHome.png",
    text:
      "FINEX was created for CS 307, Software Engineering at Purdue. I, along with a team " +
            "of 5 other students, created a web application using primarily React and Express.js " +
            "that allowed individuals to manage a simualted stocks, create a budget and become " +
            "more financially literate.",
    date: "January - May 2020",
    link: "https://github.com/hughbromund/FINEX",
    languages: ["React", "Express", "Node.js", "MongoDB"],
  },
  {
    title: "ADVENTUROUS SLOTH",
    imageName: "AdventurousSlothHome.jpg",
    text:
      "Adventurous Sloth is a web application used to recommend travel locations to users based " +
            "on their preferences. It was created for the Boilermake VII hackathon by myself and three " +
            "others. The project also won us third place at that year's hackathon!",
    date: "January 2020",
    link: "https://github.com/hughbromund/AdventurousSloth",
    languages: ["React", "Express", "Node.js"],
  },
  {
    title: "HOTEL RECOMMENDER",
    imageName: "DatathonSlide.png",
    text:
      "For the 2019 Brown datathon, I, as part of a four person team, developed a machine learning " +
            "algorithm to recommend hotels to users based on their activity on TripAdvisor, a sponsor " +
            "of the event. The project used XG Boost to correctly predict a hotel the user was likely to click " +
            "on. The project earned us the position of finalist at that year's competition.",
    date: "February 2019",
    link: "https://github.com/adityan9900/Tejuthon",
    languages: ["Python", "Pytorch"],
  },
  {
    title: "CANDOR",
    imageName: "CandorApp.png",
    text:
      "Candor is an android application developed in Android Studio for Purdue's Hello World hackathon " +
            "by myself and three others. The app allows users to view a company's environmental rating " +
            "in an easily comprehensible format, thus allowing them to make more informed purchases. " +
            "For the app, our team became one of the winners of the competition.",
    date: "September 2018",
    link: "https://github.com/adityan9900/Candor",
    languages: ["Java", "XML", "Android Studio"],
  },
];

export const EXPERIENCES_LIST = [
  {
    title: ["Software Development Engineer"],
    company: "Amazon",
    text:
      ["At Amazon I currently work on helping to manage taxes for many Amazon customers and businesses. I worked primarily on a " +
        "project that would enable clients to embed a form directly into their site without having to redirect to " +
        "it as was done before."],
    location: ["Bellevue, WA"],
    date: ["January 2022 - Present"],
    link: "https://www.amazon.com/",
  },
  {
    title: ["Software Engineer", "Software Engineering Intern"],
    company: "Fidelity Investments",
    text:
      ["Post-graduation, I worked with a team on tax-loss harvesting and optimization. More simply, I worked " +
        "on the systems designed to optimize the account in which assets are placed. I learned a lot, epecially about " +
        "working with a CI/CD pipeline, but valued the input my more experience team members had for me even more.",
      "At Fidelity I worked with Selenium and Cucumber to test one of their brokerage products. " +
        "Additionally, I worked with data for a model that utilized Keras and TensorFlow to classify images and identify " +
        "errors in other products. I was also able to learn a lot about the finance sector as " +
        "including basic options strategies and how companies monetize their products."],
    date: ["June 2021 - December 2021", "June 2020 - July 2020"],
    location: ["Boston, MA", "Merrimack, NH"],
    link: "https://www.fidelity.com/",
  },
  {
    title: ["Undergraduate Research Assistant"],
    company: "Purdue University",
    text:
      ["During my sophomore year, I worked as an undergraduate research assistant to " +
            "professor Jennifer Neville and a team of graduate students on a DARPA funded project. " +
            "The project involved parsing emails and comprehending both their motive and " +
            "maliciousness using machine learning techniques."],
    date: ["September 2019 - May 2020"],
    location: ["West Lafayette, IN"],
    link: "https://www.cs.purdue.edu/people/faculty/neville/",
  },
  {
    title: ["Software Engineering Intern"],
    company: "Siemens",
    text:
      ["For my first internship, I worked as an intern for Siemens' research and development office. " +
            "There, I worked with XCode and python, using Open CV and Tensorflow to analyze images from an " +
            "iOS application. To perform these operations, I worked with Flask as well as a mySQL database. " +
            "Additionally, I worked on testing for production code."],
    date: ["June 2019 - August 2019"],
    location: ["Norwood, MA"],
    link: "https://new.siemens.com/global/en.html",
  },
  {
    title: ["Software Co-Captain"],
    company: "Stormgears FRC",
    text:
      ["Although I was in high school when becoming part of Stormgears FRC, I value my time there immensely. " +
            "Our team competed in the FIRST Robotics Competition and qualified for the world championships in each " +
            "of my four yeras on the team. Additionally, we hosted many outreach events in our local community and " +
            "raised thousands of dollars through company sponsorships."],
    date: ["September 2014 - April 2018"],
    location: ["Devens, MA"],
    link: "http://www.stormgears.org/",
  },
];
